import React, { useState } from "react"
import { Link } from "gatsby"
import Icon from "../utils/Icon"
import { Navbar, Nav, Container } from "react-bootstrap"

const Header = () => {
  const [isActive, setActive] = useState(false)

  const handleClick = () => setActive(isActive ? false : true)

  return (
    <header className={`animated fadeIn ${isActive ? "fixed" : ""}`}>
      <Navbar expand="lg">
        <Container>
          <div className="logo-wrap">
            <Link className="navbar-brand" to="/">
              <Icon name="jaydevanimator-logo" />
              Jaydevanimator
            </Link>
            <Navbar.Toggle aria-controls="header" onClick={handleClick} />
          </div>
          <Navbar.Collapse id="header">
            <Nav className="ml-auto">
              <Link className="nav-link" to="/">
                UI/UX Portfolio
              </Link>
              <Nav.Link
                target="_blank  "
                href="https://www.behance.net/jaydevanimator"
              >
                Other Works
              </Nav.Link>
              <Link className="nav-link" to="/about/">
                About
              </Link>
              <Link className="nav-link" to="/contact">
                Contact
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header
