import React from "react"

const Icon = props => {
  return (
    <i
      className={`icon icon-${props.name}`}
      style={{ fontSize: props.size, color: props.color }}
    />
  )
}

export default Icon
