import React from "react"
import Header from "./header"
import Footer from "./footer"

const Layout = props => (
  <React.Fragment>
    <Header />
    <main className={`layout ${props.className}`}>{props.children}</main>
    <Footer />
  </React.Fragment>
)

export default Layout
