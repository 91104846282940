import React from "react"
import Icon from "../utils/Icon"
import { Container, Nav } from "react-bootstrap"

const Footer = () => {
  return (
    <footer>
      <Container>
        <p>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.behance.net/jaydevanimator"
          >
            Copyright@jaydevanimator.com
          </a>
        </p>
        <Nav>
          <Nav.Item>
            <Nav.Link
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.behance.net/jaydevanimator"
            >
              <Icon name="behance" size="1.5em" />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              rel="noopener noreferrer"
              target="_blank"
              href="https://dribbble.com/jaydevanimator"
            >
              <Icon name="dribbble" size="1.5em" />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.instagram.com/jaydevanimator/"
            >
              <Icon name="instagram" size="1.5em" />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.linkedin.com/in/jaydevanimator/"
            >
              <Icon name="linkedin" size="1.3em" />
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Container>
    </footer>
  )
}

export default Footer
