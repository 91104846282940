import React from "react"
import Typist from "react-typist"
import { Row, Col, Container } from "react-bootstrap"

const Title = props => {
  return (
    <div className="main-title animated fadeIn">
      {props.title !== undefined && props.description && (
        <Container>
          <Row>
            <Col xl={props.isFull ? 12 : 8}>
              <Typist className="MyTypist title-headding" avgTypingDelay={70}>
                {props.title}
              </Typist>
              <Typist className="MyTypist title-description" avgTypingDelay={5}>
                {props.description}
              </Typist>
              {props.status !== undefined && (
                <span className="status">{props.status}</span>
              )}
            </Col>
            {props.isDetails && (
              <Col lg={4}>
                <h3>
                  Client <em>{props.client}</em>
                </h3>
              </Col>
            )}
          </Row>
        </Container>
      )}
    </div>
  )
}

export default Title
